import Layout from "../shared/layout/layout"
import React from "react"
import SEO from "../shared/seo/seo"
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}
export default NotFoundPage
